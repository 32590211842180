@mixin page-break-in-print {
    @media print {
        page-break-before: always;
    }
}

.rest-business-summary-page {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 3rem);

    .header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;

        border: 1px solid #00000020;

        z-index: 2;

        .bx--label {
            display: none;
        }
    }

    .content {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        padding-block: 0.5rem;
        padding-inline: 1rem;

        border: 1px solid #00000020;
    }

    @media (max-width: 767px) {
        .content {
            padding: 0.5rem;
        }
    }

    @media (min-width: 768px) {
        .content {
            padding-block: 3rem;
        }
    }
}

.rest-business-summary-result-ui {
    @media (max-width: 767px) {
        width: unset;
    }
    @media (min-width: 767px) {
        width: 767px;
    }

    .report-store {
        @media (max-width: 767px) {
            // small
            font-size: 18px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 22px;
        }
    }

    h1 {
        @media (max-width: 767px) {
            // small
            font-size: 20px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 24px;
        }
    }

    h2 {
        @media (max-width: 767px) {
            // small
            font-size: 20px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 22px;
        }
        font-weight: bold;
    }

    h3 {
        @media (max-width: 767px) {
            // small
            font-size: 20px;
            padding-bottom: 0.15rem;
        }
        @media (min-width: 767px) {
            // large
            font-size: 22px;
            padding-bottom: 0.25rem;
        }

        font-weight: bold;
    }

    h4 {
        @media (max-width: 767px) {
            // small
            font-size: 18px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 20px;
        }
    }

    h5 {
        @media (max-width: 767px) {
            // small
            font-size: 16px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 18px;
        }
    }

    p {
        @media (max-width: 767px) {
            // small
            font-size: 16px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 16px;
        }
    }

    .company-label {
        @media (max-width: 767px) {
            // small
            font-size: 26px;
        }
        @media (min-width: 767px) {
            // large
            font-size: 32px;
        }
        font-weight: bolder;
    }

    .rbs-section-header {
        background: #f1f1f1;
        width: 100%;

        display: flex;
        justify-content: space-between;
        gap: 0.25rem;

        position: relative;

        @media (max-width: 767px) {
            // small
            padding-inline: 0.5rem;
            padding-block: 0.25rem;
            margin-bottom: 0.5rem;
        }
        @media (min-width: 767px) {
            // large
            padding-inline: 1rem;
            padding-block: 0.75rem;
            margin-bottom: 1rem;
        }

        @media print {
            padding-inline: 0rem !important;
            background: unset !important;
        }
    }

    .rbs-accordion-btn-icon {
        transition: 250ms;
    }

    .rbs-section-children {
        transition: 250ms;
    }

    .rbs-group-lines {
        transition: 250ms;
    }

    .rbs-detail-subgroups {
        transition: 250ms;
    }

    .rbs-sub-children {
        transition: 250ms;
    }

    .rbs-summary-children {
        transition: 250ms;
    }

    [data-expanded="true"] {
        .rbs-accordion-btn-icon {
            transform: rotate(90deg);
        }

        .rbs-section-children {
            opacity: 1;
            max-height: var(--elem-height);
        }

        .rbs-group-lines {
            opacity: 1;
            max-height: var(--elem-height);
        }

        .rbs-detail-subgroups {
            opacity: 1;
            max-height: var(--elem-height);
        }

        .rbs-sub-children {
            opacity: 1;
            max-height: var(--elem-height);
        }

        .rbs-summary-children {
            opacity: 1;
            max-height: var(--elem-height);
        }
    }

    [data-expanded="false"] {
        .rbs-accordion-btn-icon {
            transform: rotate(0deg);
        }

        .rbs-section-children {
            opacity: 0;
            max-height: 25px;
            margin-bottom: -2rem;
            pointer-events: none;
        }

        .rbs-group-lines {
            opacity: 0;
            max-height: 25px;
            margin-bottom: -2rem;
            pointer-events: none;
        }

        .rbs-detail-subgroups {
            opacity: 0;
            max-height: 25px;
            margin-bottom: -2rem;
            pointer-events: none;
        }

        .rbs-sub-children {
            opacity: 0;
            max-height: 25px;
            margin-bottom: -2rem;
            pointer-events: none;
        }

        .rbs-summary-children {
            opacity: 0;
            max-height: 25px;
            margin-bottom: -2rem;
            pointer-events: none;
        }
    }

    .rbs-accordion-btn {
        position: absolute;
        left: -3rem;
        top: 50%;
        transform: translateY(-50%);
        height: 35px;
        width: 35px;
    }

    .rbs-group .rbs-accordion-btn {
        margin-top: -0.25rem;
    }

    .rbs-detail-header .rbs-accordion-btn {
        margin-top: -0.25rem;
    }

    .rbs-sub-header .rbs-accordion-btn {
        margin-top: -0.25rem;
    }

    .rbs-summary-header .rbs-accordion-btn {
        margin-top: -0.25rem;
    }

    .rbs-section-value {
        color: green;
        text-align: right;
    }

    .rbs-group-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid #00000020;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-line-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid #00000015;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .empty-message {
        opacity: 0.65;
        font-size: 14px;
        text-align: center;
        width: 100%;
    }

    .rbs-title-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .report-gen-message {
        text-align: center;
        width: 100%;
        font-style: italic;
    }

    .report-gen-group {
        margin-block: 1.5rem;
    }

    .rbs-gen-by-msg {
        font-size: 14px;
        opacity: 0.65;
    }

    .rbs-title-group {
        flex: 1;
    }

    .rbs-title-logo {
        object-fit: contain;
        object-position: center;

        @media (max-width: 767px) {
            // small
            width: 150px;
        }
        @media (min-width: 767px) {
            // large
            width: 225px;
        }
    }

    .rbs-section {
        margin-bottom: 3rem;
    }

    .rbs-section-components {
        @media (max-width: 767px) {
            // small
            padding-inline: 0.25rem;
        }
        @media (min-width: 767px) {
            // large
            padding-inline: 1rem;
        }
    }

    .rbs-group {
        margin-bottom: 2rem;
    }

    .rbs-group-lines {
        @media (max-width: 767px) {
            // small
            padding-left: 0.25rem;
        }
        @media (min-width: 767px) {
            // large
            padding-left: 1rem;
        }
    }

    .rbs-line {
        margin-bottom: 0.25rem;
    }

    .rbs-detail-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid #00000020;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-detail {
        margin-bottom: 2rem;
    }

    .rbs-detail-subgroups {
        @media (max-width: 767px) {
            // small
            padding-left: 0.25rem;
        }
        @media (min-width: 767px) {
            // large
            padding-left: 1rem;
        }
    }

    .rbs-sub-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid #00000015;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-sub-footer {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        align-items: center;
        width: 100%;
        gap: 1rem;
        margin-top: 0.5rem;
    }

    .rbs-footer-divider {
        flex: 1;
        height: 1px;
        @media screen {
            background: #00000040;
        }
        @media print {
            border-bottom: 1px solid #00000040;
        }
    }

    .rbs-sub-orders {
        @media (max-width: 767px) {
            // small
            padding-left: 0.25rem;
        }
        @media (min-width: 767px) {
            // large
            padding-left: 1rem;
        }
    }

    .rbs-sub {
        margin-bottom: 3rem;
    }

    .rbs-order {
        margin-bottom: 0.15rem;
    }

    .rbs-order-no {
        font-weight: bold;
        margin-left: 0.15rem;
        @media screen {
            color: #0f62fe;
            cursor: pointer;
            &:hover {
                color: #0056b3;
                text-decoration: underline;
            }
        }
    }

    .rbs-order-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        align-items: center;
        width: 100%;
    }

    .rbs-order-props {
        @media (max-width: 767px) {
            // small
            padding-left: 0.25rem;
        }
        @media (min-width: 767px) {
            // large
            padding-left: 1rem;
        }
    }

    .rbs-group-value {
        text-align: right;
    }

    .rbs-line-value {
        text-align: right;
    }

    .rbs-detail-value {
        text-align: right;
    }

    .rbs-sub-value {
        text-align: right;
    }

    .rbs-order-value {
        text-align: right;
    }

    .rbs-summary-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid #00000020;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-summary {
        margin-bottom: 2rem;
    }

    .rbs-summary-children {
        padding-top: 1rem;
        @media (max-width: 767px) {
            // small
            padding-left: 0.25rem;
        }
        @media (min-width: 767px) {
            // large
            padding-left: 1rem;
        }
    }

    .rbs-summary-title-nested {
        font-style: italic;
        font-weight: normal;
    }
}

.rest-business-summary-result-thermal {
    font-family: "IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
    width: 512px;
    max-width: 512px;
    background: transparent;

    padding-block: 1rem;

    * {
        font-weight: bold !important;
    }

    .report-store {
        font-size: 18px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }

    .company-label {
        font-size: 26px;
        font-weight: bolder;
    }

    .rbs-section-header {
        width: 100%;

        display: flex;
        justify-content: space-between;
        gap: 0.25rem;

        padding-block: 0.25rem;
        margin-bottom: 0.5rem;

        border-block: 4px solid black;
    }

    .rbs-accordion-btn {
        display: none;
    }

    .rbs-section-value {
        text-align: right;
    }

    .rbs-group-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid black;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-line-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px dashed black;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .empty-message {
        opacity: 1;
        font-size: 14px;
        text-align: center;
        width: 100%;
    }

    .rbs-title-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .report-gen-message {
        text-align: center;
        width: 100%;
    }

    .report-gen-group {
        margin-block: 1.5rem;
    }

    .rbs-gen-by-msg {
        opacity: 1;
    }

    .rbs-title-group {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            text-align: center;
        }
    }

    .rbs-title-logo {
        display: none;
    }

    .rbs-section {
        margin-bottom: 3rem;
    }

    .rbs-section-components {
        padding-inline: 0.25rem;
    }

    .rbs-group {
        margin-bottom: 2rem;
    }

    .rbs-group-lines {
        padding-left: 0.5rem;
    }

    .rbs-line {
        margin-bottom: 0.25rem;
    }

    .rbs-detail-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid black;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-detail {
        margin-bottom: 2rem;
    }

    .rbs-detail-subgroups {
        padding-left: 0.5rem;
    }

    .rbs-sub-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px dashed black;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .rbs-sub-footer {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        align-items: center;
        width: 100%;
        gap: 1rem;
        margin-top: 0.5rem;
    }

    .rbs-footer-divider {
    }

    .rbs-sub-orders {
        padding-left: 0.5rem;
    }

    .rbs-sub {
        margin-bottom: 3rem;
    }

    .rbs-order {
        margin-bottom: 0.15rem;
    }

    .rbs-order-no {
        font-weight: bold;
        margin-left: 0.15rem;
    }

    .rbs-order-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        align-items: center;
        width: 100%;
    }

    .rbs-order-props {
        padding-left: 1rem;
    }

    .rbs-group-value {
        text-align: right;
    }

    .rbs-line-value {
        text-align: right;
    }

    .rbs-detail-value {
        text-align: right;
    }

    .rbs-sub-value {
        text-align: right;
    }

    .rbs-order-value {
        text-align: right;
    }

    .rbs-summary-header {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
        width: 100%;

        border-bottom: 1px solid black;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        position: relative;
    }

    .rbs-summary {
        margin-bottom: 2rem;
    }

    .rbs-summary-children {
        padding-top: 1rem;
        padding-left: 0.5rem;
    }

    .rbs-summary-title-nested {
        font-style: italic;
        font-weight: normal;
    }
}
